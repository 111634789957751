import React from "react"
import Helmet from "react-helmet"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Container, Typography, } from "@material-ui/core"
import { makeStyles, responsiveFontSizes, createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles";

const theme = responsiveFontSizes(createMuiTheme());

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#c31432",
    background: "linear-gradient(to right, #c31432, #240b36);",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "white",
  }
}))

export default function IndexPage() {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        meta={[
          {
            name: `viewport`,
            content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no`,
          },
        ]}        >
        <title>Marie-Pierre Chaudet — artiste peintre</title>
      </Helmet>
      <div className={classes.root}>
        <CssBaseline />
        <Container component='main' className={classes.main} maxWidth='md'>
          <Typography variant='h1'>Marie-Pierre Chaudet</Typography>
          <Typography variant='h2'>artiste peintre</Typography>
        </Container>
      </div>
    </ThemeProvider>
  )
}
